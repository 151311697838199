import React from 'react';
import { Backdrop, makeStyles } from "@material-ui/core";
import ReactLoading from "react-loading";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#3699FF',
  },
}));

export default function GlobalLoader() {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={true}>
        <ReactLoading type='bars' color="#fff" />
      </Backdrop>
    </div>
  );
}