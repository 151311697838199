import { GET_ORDERS, GET_SINGLE_ORDER } from "../../actions/types";

// activity intial state
const initialProduct = {
    orders: null,
    singleOrder: null
};

export const reducer = (state = initialProduct, action) => {
    switch (action.type) {
        case GET_ORDERS: {
            const orders = action.payload;
            return {
                ...state,
                orders: orders,
            };
        }

        case GET_SINGLE_ORDER: {
            let arr = state.orders.docs.filter((item) => item.shopify_order_id === action.payload)
            arr = arr.values();
            for (let val of arr) {
                arr = val
            }
            
            return {
                ...state,
                singleOrder: arr,
            };
        }

        default:
            return state;
    }
};