import { API_END_POINTS } from "../../constant/config";
import JsonInterceptor from "../../utils/JsonInterceptor";
import { GET_PAYMENT_INFO, CANCEL_SUBSCRIPTION, CREATE_CHARGE, SHOPIFY_SUBSCRIPTION } from "../types";
const store = require('store');

export const getPaymentInfo = (query) => async (dispatch) => {
    try {

        const apiEndpoint = `${API_END_POINTS.GET_PAYMENT_INFO}?shop=${query.store}`;
        const response = await JsonInterceptor.get(`${apiEndpoint}`);
        store.set('plan_info',response.data.data);
        
        return dispatch({
            type: GET_PAYMENT_INFO,
            payload: response.data.data,
        });


    } catch (error) {
        console.log("error", error);
    }
};

export const getSubscriptionPlansList = () => async (dispatch) => {
    try {
        const apiEndpoint = `${API_END_POINTS.SHOPIFY_SUBSCRIPTION}`;
        const response = await JsonInterceptor.get(`${apiEndpoint}`);
        store.set('all_plan_info',response.data.data);

        return dispatch({
            type: SHOPIFY_SUBSCRIPTION,
            payload: response.data.data,
        });
    } catch (error) {
        console.log("error", error);
    }
};


export const cancelSubscription = (query) => async (dispatch) => {
    try {

        const apiEndpoint = `${API_END_POINTS.CANCEL_SUBSCRIPTION}?shop=${query.store}`;
        const response = await JsonInterceptor.get(`${apiEndpoint}`);
        
        return dispatch({
            type: CANCEL_SUBSCRIPTION,
            payload: response.data,
        });

    } catch (error) {
        // console.log("error", error);
        if (error.response) {
            return dispatch({
                type: CANCEL_SUBSCRIPTION,
                payload: error.response.data,
            });
        }
    }
};

export const createCharge = (query) => async (dispatch) => {
    try {
        
        const apiEndpoint = `${API_END_POINTS.CREATE_CHARGE}?shop=${query.store}&plan_id=${query.plan_id}&plan_type=${query.plan_type}&charge_id=${query.charge_id}&price=${query.price}&name=${query.name}&inventory_type=${query.inventory_type}&plan_inventory=${query.plan_inventory}&previous_plan_inventory=${query.previous_plan_inventory}`;
        const response = await JsonInterceptor.get(`${apiEndpoint}`);
        // plan_detail
        
        return dispatch({
            type: CREATE_CHARGE,
            payload: response.data,
        });

    } catch (error) {
        // console.log("error", error);
        if (error.response) {
            return dispatch({
                type: CREATE_CHARGE,
                payload: error.response.data,
            });
        }
    }
};