import { TOGGEL_BRAND } from "../types";

export const getToggleState = (toggelState) => (dispatch) => {
    try {
        dispatch({
          type: TOGGEL_BRAND,
          payload: toggelState
        });
  
    } catch (error) {
        console.log("error", error);
    }
};