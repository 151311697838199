import React, { Suspense, useEffect, useState, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
// import { DashboardPage } from "./pages/DashboardPage";

//------------code commented at 1-04-2023 by HS due to set lazy component---------

// import { AllinventoryPage } from "./pages/AllInventory/AllInventoryPage";
// import { MyinventoryPage } from "./pages/MyInventory/MyInventoryPage";
// import { MyOrderPage } from "./pages/MyOrder/OrderPage";
// import { MarketingMaterialPage } from "./pages/MarketingMaterial/MarketingMaterialPage";
// import { MyAccountPage } from "./pages/MyAccount/MyAccounPage";
// import { HelpPage } from "./pages/Help/Help";
// import { ReturnPage } from "./pages/Return/ReturnPage";
// import { TurnkeyWebsitePage } from "./pages/TurnkeyWebsite/TurnkeyWebsitePage";
// import { UpgradeSavePage } from "./pages/UpgradeSave/UpgradeSavePage";
// import { Modal } from 'antd';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchPopupContent } from '../actions/popup/popup';
import { Button, Modal } from "react-bootstrap";

const AllinventoryPage = lazy(() => import("./pages/AllInventory/AllInventoryPage") );
const MyinventoryPage = lazy(() => import("./pages/MyInventory/MyInventoryPage") );
const MyOrderPage = lazy(() => import("./pages/MyOrder/OrderPage") );
const MarketingMaterialPage = lazy(() => import("./pages/MarketingMaterial/MarketingMaterialPage") );
const MyAccountPage = lazy(() => import("./pages/MyAccount/MyAccounPage") );
const CartPage = lazy(() => import("./pages/Cart/CartPage") );
const HelpPage = lazy(() => import("./pages/Help/Help") );
const ReturnPage = lazy(() => import("./pages/Return/ReturnPage") );
const TurnkeyWebsitePage = lazy(() => import("./pages/TurnkeyWebsite/TurnkeyWebsitePage") );
const UpgradeSavePage = lazy(() => import("./pages/UpgradeSave/UpgradeSavePage") );

const store = require('store');
const popupshownFlag = store.get('popupshown');

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const [showPopup, setShowpopup] = useState(false);

  const { popupInfo } = useSelector(
    (state) => ({
      popupInfo: state?.popupInfo && state?.popupInfo?.popupInfo
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {

    async function getPopupInfo() {
      await dispatch(fetchPopupContent());
    }
    getPopupInfo();

  }, [dispatch])

  
  useEffect(() => {
    const timer = setTimeout(() => {
      if(popupshownFlag && popupInfo && popupInfo.active === true){
        setShowpopup(popupshownFlag);
      }
    }, 2000);
    return () => clearTimeout(timer);
  },[popupInfo]);

  const closePopup = () => {
    setShowpopup(false);
  }

  return (
    <>
      {/* <Suspense fallback={<LayoutSplashScreen />}> */}
        <Switch>
          <Suspense fallback={<></>}>

            {
              /* Redirect from root URL to /dashboard. */
              
              // <Redirect exact from="/" to="/all-inventory" />
            }

            {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
            <ContentRoute path="/all-inventory" component={AllinventoryPage} />
            <ContentRoute path="/my-inventory" component={MyinventoryPage} />
            <ContentRoute path="/my-orders" component={MyOrderPage} />
            <ContentRoute path="/marketing-material" component={MarketingMaterialPage} />
            <ContentRoute path="/my-account" component={MyAccountPage} />
            <ContentRoute path="/cart" component={CartPage} />
            <ContentRoute path="/help" component={HelpPage} />
            <ContentRoute path="/return" component={ReturnPage} />
            <ContentRoute path="/trunkey" component={TurnkeyWebsitePage} />
            <ContentRoute path="/upgrade-save" component={UpgradeSavePage} />

            {/* lazy route */}
            {/* <Route path="/all-inventory" component={AllinventoryPage} />
            <Route path="/my-inventory" component={MyinventoryPage} />
            <Route path="/my-orders" component={MyOrderPage} />
            <Route path="/marketing-material" component={MarketingMaterialPage} />
            <Route path="/my-account" component={MyAccountPage} />
            <Route path="/help" component={HelpPage} />
            <Route path="/return" component={ReturnPage} />
            <Route path="/trunkey" component={TurnkeyWebsitePage} />
            <Route path="/upgrade-save" component={UpgradeSavePage} /> */}

            {/* <Redirect to="error/error-v1" /> */}
          </Suspense>
        </Switch> 
      {/* </Suspense> */}

      {/* offer sale popup */}
      {/* <Modal
        title=""
        centered
        style={{ top: 10 }}
        visible={showPopup}
        onCancel={() => closePopup()}
        footer={[
          <button
            key="cancel"
            type="submit"
            className={`btn btn-light btn-md font-weight-bold text-uppercase text-center mr-2`}
            onClick={() => {
              closePopup();
            }}
          >
            Close
          </button>
        ]}
        width='70%'
      >
        <div className="mt-5">
          <div dangerouslySetInnerHTML={{ __html: (popupInfo && popupInfo?.description) }}></div>
        </div>
      </Modal> */}

      <div>
        <Modal show={showPopup} onHide={() => closePopup()} size="lg" centered className="init_modal">
          <Modal.Header closeButton>
            <Modal.Title> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-5">
              <div dangerouslySetInnerHTML={{ __html: (popupInfo && popupInfo?.description) }}></div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closePopup()} className={`btn btn-light btn-md font-weight-bold text-uppercase text-center mr-2`}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

    </>
  );
}
