export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_ALL_INVENTORY_PRODUCTS = "GET_ALL_INVENTORY_PRODUCTS";
export const GET_MY_INVENTORY_PRODUCTS = "GET_MY_INVENTORY_PRODUCTS";
export const CATEGORY_FILTER = "CATEGORY_FILTER";
export const SEARCH_KEYWORD = "SEARCH_KEYWORD";
export const SORT_fILTER = "SORT_fILTER";
export const RESET_LOADING = "RESET_LOADING";
export const RESET_FILTER = "RESET_FILTER";
export const CHECK_MAINTAINANCE_MODE = "CHECK_MAINTAINANCE_MODE";
export const GET_MARKETING_MATERIAL_BANNER = "GET_MARKETING_MATERIAL_BANNER";
export const GET_ORDERS = "GET_ORDERS";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const SEND_RETURN_MAIL = "SEND_RETURN_MAIL";
export const GET_SHOPIFY_COLLECTION = "GET_SHOPIFY_COLLECTION";
export const PRODUCT_ADD_TO_SHOPIFY = "PRODUCT_ADD_TO_SHOPIFY";
export const REMOVE_PRODUCT_FROM_SHOPIFY = "REMOVE_PRODUCT_FROM_SHOPIFY";
export const GET_PAYMENT_INFO = 'GET_PAYMENT_INFO';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CREATE_CHARGE = 'CREATE_CHARGE';
export const TOGGEL_BRAND = "TOGGEL_BRAND";
export const REFRESH_CATEGORY_TREE = "REFRESH_CATEGORY_TREE";
export const CURRENT_SHOP = "CURRENT_SHOP";
export const SHOPIFY_SUBSCRIPTION = "SHOPIFY_SUBSCRIPTION";
export const GET_POPUP_CONTENT = "GET_POPUP_CONTENT";
export const PAYMENT_CHECKOUT = "PAYMENT_CHECKOUT";
export const VALIDATE_PAYMENT = "VALIDATE_PAYMENT";
export const MANUAL_FULFILLMENT_SERVICE = "MANUAL_FULFILLMENT_SERVICE"
export const ADD_TO_CART = "ADD_TO_CART";
export const CART_ITEMS_COUNT = "CART_ITEMS_COUNT";


