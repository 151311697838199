import { TOGGEL_BRAND } from "../../actions/types";

// activity intial state
const initialToggleState = {
    toggelState: true,
};

export const reducer = (state = initialToggleState, action) => {
    switch (action.type) {
        case TOGGEL_BRAND: {
            const toggelState = action.payload;
            return {
                ...state,
                toggelState: toggelState,
            };
        }

        default:
            return state;
    }
};