/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
// import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { ShopContext } from "./ShopContext";

const localstore = require('store');
export default function App({ store, persistor, basename }) {
  const query = new URLSearchParams(window.location.search);
  let shop = query.get("shop");
  let hmac = query.get("hmac");

  if (shop) {
    localstore.set("shop", shop);
  }
  if (hmac) {
    localstore.set("hmac", hmac);
  }

  shop = localstore.get("shop");
  // console.log(shop);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              {/* <I18nProvider> */}
                {/* Render routes with provided `Layout`. */}
                <ShopContext>
                  <Routes />
                </ShopContext>
              {/* </I18nProvider> */}
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
