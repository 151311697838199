import { GET_MARKETING_MATERIAL_BANNER } from "../../actions/types";

// activity intial state
const initialProduct = {
    material: null
};

export const reducer = (state = initialProduct, action) => {
    switch (action.type) {
        case GET_MARKETING_MATERIAL_BANNER: {
            const banners = action.payload;
            return {
                ...state,
                material: banners,
            };
        }

        default:
            return state;
    }
};


