import axios from "axios";
import { API_ROOT } from "../constant/config";

//create instance
const JsonInterceptor = axios.create({
  baseURL: API_ROOT,
});

// // Add a request interceptor
JsonInterceptor.interceptors.request.use(
    async (config) => {
        config.headers["Content-Type"] = "application/json;charset=utf-8";
        return config;
    },
    (error) => {
        console.log(error);
        if (error.response && error.response) {
        console.log(error.response.data, "error.response.data");
        return Promise.reject(error.response);
        }
        return Promise.reject(error);
    }
);

export default JsonInterceptor;