/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react"
import { useLocation } from "react-router"
import { NavLink } from "react-router-dom"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers"
import { useDispatch, useSelector, shallowEqual } from "react-redux";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : ""
  }
  const { itemscount } = useSelector((state) => ({
    itemscount:
      state.products && state.products.cartitemcount
  }))

  const menuList = [
    {
      key: "all-inventory",
      icon: "/media/svg/icons/Layout/Layout-grid.svg",
      name: "All Inventory",
      width: "113px"
    },
    {
      key: "my-inventory",
      icon: "/media/svg/icons/Layout/Layout-top-panel-4.svg",
      name: "My Inventory",
      width: "119px"
    },
    {
      key: "my-orders",
      icon: "/media/svg/icons/Shopping/Cart2.svg",
      name: "My Orders",
      width: "102px"
    },
    {
      key: "marketing-material",
      icon: "/media/svg/icons/Files/Selected-file.svg",
      name: "Marketing Material",
      width: "159px"
    },
    {
      key: "my-account",
      icon: "/media/svg/icons/General/User.svg",
      name: "My Account",
      width: "113px"
    },
    {
      key: "help",
      icon: "/media/svg/icons/Code/Question-circle.svg",
      name: "Help",
      width: "55px"
    },
    {
      key: "return",
      icon: "/media/svg/icons/Code/Time-schedule.svg",
      name: "Returns",
      width: "75px"
    },
    {
      key: "cart",
      icon: "bi bi-cart",
      name: "Cart",
      width: "50px"
    },
    {
      key: "trunkey",
      icon: "/media/svg/icons/Design/Layers.svg",
      name: "$99 TURNKEY WEBSITE",
      width: "178px"
    },
    {
      key: "upgrade-save",
      icon: "/media/svg/icons/Tools/Tools.svg",
      name: "UPGRADE & SAVE",
      width: "145px"
    },
   
  ]

  // check shop exist or not
  const path = window.location.href.replace(/^.+\?/i, "")
  const searchParams = new URLSearchParams(path)
  const shop = searchParams.get("shop")
  const host = searchParams.get("host")

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul
        className={`menu-nav ${layoutProps.ulClasses}`}
        style={{ whiteSpace: "nowrap" }}
      >
        {menuList &&
          menuList.map((item, index) => {
            return (
              
              <li
              className={`menu-item menu-item-rel ${
                (item.key === "trunkey" || item.key === "upgrade-save") && "promo_page"
              } ${getMenuItemActive(item.key)}`}
              key={index}
              /* style={{width:`${item.width}`}} */
            >
            
                 {item.key === "cart" ? (
           <NavLink
           className="menu-link position-relative d-flex align-items-center"
           to={`${item.key}?shop=${shop}&host=${host}`}
           style={{ textDecoration: 'none' }}
         >
           <div className="position-relative">
             <i
               className="bi bi-cart4"
               style={{ fontSize: '2.5rem', color: '#b06349', marginRight: '0.5rem' }}
             ></i>
             <span
               className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary mt-2"
               id="cart-count"
               style={{ transform: 'translate(-50%, -50%)' }}
             >
               {itemscount || 0}
             </span>
           </div>
           <span 
             className="menu-text"
             style={{ fontSize: '1rem', color: '#3f4254' }}
           >
             Cart
           </span>
           {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
         </NavLink>
         
            ) : (
              <NavLink
                className="menu-link"
                to={`${item.key}?shop=${shop}&host=${host}`}
              >
                <span className="menu-text">{item.name}</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            )}
              </li>
            )
          })}
      </ul>
      {/*end::Header Nav*/}
    </div>
  )
}
