import { GET_CATEGORIES } from "../../actions/types";

// activity intial state
const initialCategory = {
    categories: null,
    isLoading: true,
};

export const reducer = (state = initialCategory, action) => {
    switch (action.type) {
        case GET_CATEGORIES: {
            const categories = action.payload;
            return {
                ...state,
                categories: categories,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};