import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as orders from  "../reducers/orders/orders";
import * as toggel from  "../reducers/toggel/toggel";

import * as material from  "../reducers/marketing/marketing";
import * as categories from '../reducers/categories/categories';
import * as products from '../reducers/products/products';
import * as common from '../reducers/common';
import * as myaccount from '../reducers/myaccount/myaccount';
import * as shop from '../reducers/shop/shop';
import * as popupinfo from '../reducers/popup/popup';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  orders: orders.reducer,
  material: material.reducer,
  common: common.reducer,
  categories: categories.reducer,
  products: products.reducer,
  myaccount: myaccount.reducer,
  toggel:toggel.reducer,
  currentShop: shop.reducer,
  popupInfo: popupinfo.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
