import { CATEGORY_FILTER, SEARCH_KEYWORD, SORT_fILTER, CHECK_MAINTAINANCE_MODE, RESET_FILTER, REFRESH_CATEGORY_TREE } from "../actions/types";
const init = {
    categoryID: null,
    searchKeyword: null,
    sortFilter: null,
    maintainance_mode: null,
    refresh_flag: false,
};

export const reducer = (state = init, action) => {
    switch (action.type) {

        case CATEGORY_FILTER: {
            return { ...state, categoryID: action.categoryId }
        }

        case SEARCH_KEYWORD: {
            return { ...state, searchKeyword: action.searchKeyword }
        }

        case SORT_fILTER: {
            return { ...state, sortFilter: action.sortFilter }
        }

        case CHECK_MAINTAINANCE_MODE: {
            const mode = action.payload;

            let mode_status = mode;
            mode_status = mode_status.values();

            for (let val of mode_status) {
                mode_status = val
            }

            return {
                ...state,
                maintainance_mode: mode_status,
            };
        }

        case RESET_FILTER: {
            return {
                ...state,
                categoryID: null,
                searchKeyword: null,
                sortFilter: 'new_to_old',
            }
        }
        
        case REFRESH_CATEGORY_TREE: {
            return {
                ...state,
                refresh_flag: true
            }
        }

        default: {
            return state;
        }
    }
}