/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import * as serviceWorker from './serviceWorker';

import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout";
// import { MetronicI18nProvider } from "./_metronic/i18n";
import { SnackbarProvider } from 'notistack';
import { Provider } from '@shopify/app-bridge-react';

const localstore = require('store');
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */ _redux.mockAxios(axios);
/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

const path = window.location.href.replace(/^.+\?/i, '');
const searchParams = new URLSearchParams(path);
const shop = searchParams.get('shop');
var forceRedirect = true

if(process.env.NODE_ENV === 'production'){
  if(new URLSearchParams(window.location.search).get("bypass") === '22868300104343534024'){
    forceRedirect = false
  }
  else {
    forceRedirect = true
  }
  console.log= function(){}

}
else {
  forceRedirect = false
}

const config = {
  // apiKey: 'e6161a8f323114b2e1f70b018f434ae0', process.env.REACT_APP_CLIENT_ID
  apiKey: process.env.REACT_APP_CLIENT_ID,
  host: (process.env.NODE_ENV === 'production') ? new URLSearchParams(window.location.search).get("host") : window.btoa(shop + "/admin"), //window.btoa(shop + "/admin"),
  forceRedirect: forceRedirect
};


// set diplay popup for sale code
localstore.set('popupshown', true)

// set shop params globally
if (shop && shop !== null) {
  localstore.set('shop', shop);
}

ReactDOM.render(
  <Provider config={config}>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <SnackbarProvider maxSnack={3} style={{ fontSize: "14px" }}>
            <App store={store} persistor={persistor} basename={PUBLIC_URL} />
          </SnackbarProvider>
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </Provider>,
  document.getElementById("root")
);
// serviceWorker.register();

// https://1217-2401-4900-1f3e-6a1f-69e7-299f-4c46-9e47.ngrok.io/?shop=myonlineteststoreforapp.myshopify.com&host=YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvbXlvbmxpbmV0ZXN0c3RvcmVmb3JhcHA&bypass=22868300104343534024