import { GET_ALL_INVENTORY_PRODUCTS, GET_MY_INVENTORY_PRODUCTS, GET_SHOPIFY_COLLECTION, RESET_LOADING ,CART_ITEMS_COUNT} from "../../actions/types";

// activity intial state
const initialProduct = {
    products: null,
    myinventoryproduct: null,
    isLoading: true,
    collections: null,
    cartitemcount:null,
};

export const reducer =   (state = initialProduct, action) => {
    switch (action.type) {
        case GET_ALL_INVENTORY_PRODUCTS: {
            const products = action.payload;
            return {
                ...state,
                products: products,
                isLoading: false,
            };
        }

        case GET_MY_INVENTORY_PRODUCTS: {
            const myinventoryproduct = action.payload;
            return {
                ...state,
                myinventoryproduct: myinventoryproduct,
                isLoading: false
            };
        }

        case GET_SHOPIFY_COLLECTION: {
            const collection = action.payload;
            return {
                ...state,
                collections: collection,
                isLoading: false
            };
        }

        case RESET_LOADING: {
            return {
                ...state,
                isLoading: true,
                products: null,
                myinventoryproduct: null,
            };
          }

          case CART_ITEMS_COUNT: {
            const count = action.payload;
                    return {
                        ...state,
                        cartitemcount: count.count,
                    };
                  
        }

        default:
            return state;
    }
};