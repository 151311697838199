import { CATEGORY_FILTER, SEARCH_KEYWORD, SORT_fILTER, CHECK_MAINTAINANCE_MODE, RESET_FILTER, REFRESH_CATEGORY_TREE} from "./types";
import axios from 'axios';
import { API_END_POINTS } from "../constant/config";
const store = require('store');

export const categoryFilter = (action) => (dispatch) => {
    try {
        dispatch({
            type: CATEGORY_FILTER,
            ...action
        });

    } catch (error) {
        console.log("error", error);
    }
};

export const searchKeyword = (action) => (dispatch) => {
    try {
        dispatch({
            type: SEARCH_KEYWORD,
            ...action
        });

    } catch (error) {
        console.log("error", error);
    }
};

export const sortFilter = (action) => (dispatch) => {
    try {
        dispatch({
            type: SORT_fILTER,
            ...action
        });

    } catch (error) {
        console.log("error", error);
    }
};

export const checkMaintainanceMode = () => async (dispatch) => {
    try {
        const apiEndpoint = `${API_END_POINTS.CHECK_MAINTAINANCE_MODE}`;
        const response = await axios.get(`${apiEndpoint}`)
        
        store.set('maintenance_status',response.data.data[0].in_shopify);
        
        return dispatch({
            type: CHECK_MAINTAINANCE_MODE,
            payload: response.data.data,
        });

    } catch (error) {
        console.log("error", error);
    }
};

export const resetFilter = () => async (dispatch) => {
    try {
        dispatch({
            type: RESET_FILTER,
        });
    } catch (error) {
        console.log("error", error);
    }
};

export const refreshCategoryTree = () => async (dispatch) => {
    try {
        dispatch({
            type: REFRESH_CATEGORY_TREE,
        });
    } catch (error) {
        console.log("error", error);
    }
};

