import { CURRENT_SHOP } from "../../actions/types";
const init = {
    shop: '',
};

export const reducer = (state = init, action) => {
    switch (action.type) {

        case CURRENT_SHOP: {
            return { ...state, shop: action.shop }
        }

        default: {
            return state;
        }
    }
}