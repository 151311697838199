import { API_END_POINTS } from "../../constant/config";
import JsonInterceptor from "../../utils/JsonInterceptor";
import { GET_POPUP_CONTENT } from "../types";

// Get Popup Content
export const fetchPopupContent = () => async (dispatch) => {
    try {
        const apiEndpoint = API_END_POINTS.FETCH_POPUP_INFO;
        console.log(apiEndpoint)
        const response = await JsonInterceptor.get(`${apiEndpoint}`)
        dispatch({
            type: GET_POPUP_CONTENT,
            payload: response.data.data[0],
        });
    } catch (error) {
        console.log("error", error);
    }
};