import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkMaintainanceMode } from "../actions/common";
import { getPaymentInfo } from "../actions/myaccount/myaccount";

const ShopNameContext = createContext();

export function useShopNameContext() {
  return useContext(ShopNameContext);
}

export function ShopContext({ children }) {

  const path = window.location.href.replace(/^.+\?/i, '');
  const searchParams = new URLSearchParams(path);
  const shop = searchParams.get('shop');

  const [maintenance_mode, setmaintenance_mode] = useState(false);

  const dispatch = useDispatch();

  // Allowed Store Logic
  let allowedStoreArr = ["myonlineteststoreforapp", "my-online-fashion-store"]

  allowedStoreArr = allowedStoreArr?.map((storeName, index) => {
    return `${storeName}.myshopify.com`
  })
  // Allowed Store Logic

  useEffect(() => {
    async function maintenance_mode() {
      if (allowedStoreArr.includes(shop)) {
        setmaintenance_mode(false)
      } else {
        await dispatch(checkMaintainanceMode()).then((res) => {
          setmaintenance_mode(res.payload[0].in_shopify)
        })
      }
    }
    maintenance_mode();

  }, [dispatch]);

  const [shopName, setShopName] = useState(shop);

  const value = {
    shopName,
    maintenance_mode
  };
  return (
    <ShopNameContext.Provider value={value}>{children}</ShopNameContext.Provider>
  );
}