/* eslint-disable no-undef */
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState,lazy } from "react";
import { Redirect, Switch, Route, useHistory, useLocation } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import loadable from '@loadable/component'
import { useAppBridge } from "@shopify/app-bridge-react"
import { Redirect as AppBridgeRedirect } from "@shopify/app-bridge/actions"; // Renamed

import BasePage from "./BasePage";
import { useDispatch } from "react-redux";
import { getPaymentInfo,createCharge,getSubscriptionPlansList } from '../actions/myaccount/myaccount';
import { useShopNameContext } from "./ShopContext";
const UnauthorisedPage = loadable(() => import('./pages/UnauthorisedPage'))
const ErrorsPage = loadable(() => import('./modules/ErrorsExamples/ErrorsPage'))
const SubscriptionPage = loadable(() => import('./pages/SubscriptionPage'))
const MaintenancePage = loadable(() => import('./pages/MaintenancePage'))

const store = require('store');

export function Routes() {

  const ShopNameContext = useShopNameContext();

  const dispatch = useDispatch();

  const history = useHistory()
  const app = useAppBridge()
  const redirect = AppBridgeRedirect.create(app)
  const [planStatus, setplanStatus] = useState(true);

  const query = {
    store: ShopNameContext.shopName,
  }

  const checkPlanExpired = (plandata) => {
    if ((plandata && plandata.is_intrial === false && (plandata.plan_type === null || plandata.status === 'inactive'))) {
      setplanStatus(false);

      if(plandata?.upcomingPlanData){
        dispatch(createCharge(plandata?.upcomingPlanData)).then(async (charge_data) => {
          if (charge_data.payload.confirmation_url) {
            redirect.dispatch(
              AppBridgeRedirect.Action.REMOTE,
              charge_data.payload.confirmation_url
            )
          } 
        })
      }

    } else {
      setplanStatus(true);
    }
  }

  // check shop exist or not
  const path = window.location.href.replace(/^.+\?/i, '');
  const searchParams = new URLSearchParams(path);
  const shop = searchParams.get('shop')
  const host = searchParams.get("host")

  useEffect(() => {
    // function maintenance_mode() {

    //   dispatch(checkMaintenanceMode());

    // }
    // maintenance_mode();

    async function get_plan_info() {
      await dispatch(getPaymentInfo(query)).then((res) => {
        res !== undefined && checkPlanExpired(res.payload)
      })
    }
    get_plan_info()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, history.location.pathname])
  console.log(ShopNameContext)
  console.log("Check Build Updated or not")

  var currentLocation = history.location.pathname

  var redirectURL = `/all-inventory?shop=${shop}&host=${host}`

  return (
    <>
      <Switch>
        {/* maintenance mode logic */}
        {/* {store.get("maintenance_status") !== null && store.get("maintenance_status") ? */}
        {ShopNameContext && ShopNameContext.maintenance_mode !== false ? (
          <>
            <Redirect to={`/maintenance?shop=${shop}&host=${host}`} />
            <Route path="/maintenance" component={MaintenancePage} />
          </>
        ) : (
          <Redirect from="/maintenance" to={redirectURL} />
        )}
        {/* maintenance mode logic */}
        
        {/* plan subscription logic */}
        <Route
          path={`/subscription?shop=${shop}&host=${host}`}
          component={SubscriptionPage}
        />

        {!planStatus ? (
          <>
            <Redirect to={`/subscription?shop=${shop}&host=${host}`} />
            <Route path="/subscription" component={SubscriptionPage} />
          </>
        ) : (
          <Redirect from="/subscription" to={redirectURL} />
        )}
        {/* plan subscription logic */}

        <Route path="/error" component={ErrorsPage} />

        {shop && (shop !== null || shop !== "") ? (
          currentLocation === "/" ? (
            <Redirect from="/" to={redirectURL} />
          ) : (
            <Layout>
              <BasePage />
            </Layout>
          )
        ) : (
          // <Layout>
          //   <BasePage />
          // </Layout>
          <UnauthorisedPage />
        )}
      </Switch>
    </>
  )
}
