import { GET_PAYMENT_INFO, SHOPIFY_SUBSCRIPTION, CANCEL_SUBSCRIPTION, CREATE_CHARGE } from "../../actions/types";

// activity intial state
const initialInfo = {
    payment_info: null,
    plan_list: null
};

export const reducer = (state = initialInfo, action) => {
    switch (action.type) {
        case GET_PAYMENT_INFO: {
            const info = action.payload;
            return {
                ...state,
                payment_info: info,
            };
        }
        case SHOPIFY_SUBSCRIPTION: {
            const planlist = action.payload;
            return {
                ...state,
                plan_list: planlist,
            };
        }

        default:
            return state;
    }
};