import { GET_POPUP_CONTENT } from "../../actions/types";

const initialpopupContent = {
    popupInfo: null,
    isLoading: true,
  };

  export const reducer = (state = initialpopupContent, action) => {
    switch (action.type) {
      case GET_POPUP_CONTENT: {
        const content = action.payload;
        return {
          ...state,
          popupInfo: content,
          isLoading: false,
        };
      }
      default:
        return state;
    }
  };