import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";
import { useDispatch } from "react-redux";
import { getToggleState } from "../../../../actions/toggle/toggel";
import { Link } from "react-router-dom";
export function Brand() {

  const [toggle, setToggle] = useState(true);
  const dispatch = useDispatch()
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  // useEffect(() => {
  //   if(!toggle){
  //     if (!document.body.classList.contains('aside-minimize')) {
  //       document.body.classList.add('aside-minimize');
  //     }
  //   } else {
  //     if (document.body.classList.contains('aside-minimize')) {
  //       document.body.classList.remove('aside-minimize');
  //     }
  //   }
  // },[toggle]);

  const path = window.location.href.replace(/^.+\?/i, "")
  const searchParams = new URLSearchParams(path)
  const shop = searchParams.get("shop")
  const host = searchParams.get("host")

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}
        <Link to={`/?shop=${shop}&host=${host}`} className="brand-logo">
          <img
            alt="logo"
            src={toAbsoluteUrl("/media/storeLogo/storeLogo.jpg")}
          />
        </Link>
        {/* end::Logo */}

        {layoutProps.asideSelfMinimizeToggle && (
          <>
            {/* begin::Toggle */}
            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
              onClick={(e) => {
                setToggle(!toggle);
                dispatch(getToggleState(!toggle));
              }}
            >
              <span className="svg-icon svg-icon-xl">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Angle-double-left.svg"
                  )}
                />
              </span>
            </button>
            {/* end::Toolbar */}
          </>
        )}
      </div>
      {/* end::Brand */}
    </>
  )
}
