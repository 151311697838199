import { API_END_POINTS } from "../../constant/config";
import JsonInterceptor from "../../utils/JsonInterceptor";
import { GET_CATEGORIES } from "../types";

// Fetch Category
export const fetchCategories = (query) => async (dispatch) => {
  try {
    const apiEndpoint = `${API_END_POINTS.FETCH_CATEGORIES}?store=${query.shop}`;
    const response = await JsonInterceptor.get(`${apiEndpoint}`);
    
    return dispatch({
      type: GET_CATEGORIES,
      payload: response.data.data,
    });

  } catch (error) {
    console.log("error", error);
  }
};

// Fetch Category
export const fetchMyInventoryCategories = (query) => async (dispatch) => {
  try {
    const apiEndpoint = `${API_END_POINTS.FETCH_MY_INVENTORY_CATEGORY}?store=${query.shop}`;
    const response = await JsonInterceptor.get(`${apiEndpoint}`);
    
    return dispatch({
      type: GET_CATEGORIES,
      payload: response.data.data,
    });

  } catch (error) {
    console.log("error", error);
  }
};